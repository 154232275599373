import defaultState from "./defaultState";

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case 'SET_STATE':
      if (typeof action.getState === 'function') {
        return {
          ...state,
          ...action.getState(state)
        };
      }
      else {
        return {
          ...state,
          ...action.getState
        };
      }
    case 'REMOVE_STATE':
      const _state = {
        ...state
      };
      delete _state[action.key];
      return _state;
    default:
      return state;
  }
};

export default reducer;