import { useEffect } from 'react';
import { useSetState } from '../redux/action';

const useViewportSizeEffect = (phoneWidth) => {
  const setState = useSetState();
  useEffect(() => {
    const handler = event => {
      setState({
        isDesktop: event.matches
      });
    };
    const media = window.matchMedia(`(min-width: ${phoneWidth}px)`);
    setState({
      isDesktop: media.matches
    });
    if (media.addEventListener) {
      media.addEventListener("change", handler);
      return () => {
        media.removeEventListener("change", handler);
      };
    }
    else {
      media.addListener(handler);
      return () => {
        media.removeListener(handler);
      };
    }
  }, [phoneWidth, setState]);
};

export default useViewportSizeEffect;