import { useMemo } from "react";
import { ThemeProvider } from "styled-components";
import Theme from "../constants/Theme";
import useViewportSizeEffect from "../hooks/useViewportSizeEffect";
import useStore from "../redux/useStore";
import CustomGlobalStyle from "./CustomGlobalStyle";

const AppThemeProvider = ({ children }) => {
  const { mode, isDesktop } = useStore();
  const theme = useMemo(() => {
    return {
      ...Theme[mode],
      ...Theme.common,
      isDesktop
    };
  }, [mode, isDesktop]);
  useViewportSizeEffect(theme.dim.phoneWidth);
  return <ThemeProvider theme={theme}>
    <CustomGlobalStyle />
    {children}
  </ThemeProvider>;
};

export default AppThemeProvider;