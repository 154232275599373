import { useCallback } from "react";
import { useDispatch } from "react-redux";

const useAction = (action) => {
  const dispatch = useDispatch();
  return useCallback((...args) => {
    dispatch(action(...args));
  }, [dispatch, action]);
};

export const useSetState = () => {
  const setState = useCallback(getState => {
    return {
      type: 'SET_STATE',
      getState
    };
  }, []);
  return useAction(setState);
};

export const useRemoveState = () => {
  const setState = useCallback(key => {
    return {
      type: 'REMOVE_STATE',
      key
    };
  }, []);
  return useAction(setState);
};