import { lazy } from 'react';

const App = lazy(() => {
  const target = process.env.REACT_APP_TARGET;
  switch (target) {
    case "client":
      return import("./ClientApp");
    case "admin":
      return import("./AdminApp");
    default:
      return Promise.reject(
        new Error(`No such build target: ${target}`)
      );
  }
});

export default App;