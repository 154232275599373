import styled, { keyframes } from "styled-components";

const loadingspin = keyframes`
  100% {
    transform: rotate(360deg)
  }
`;

const Loading = styled.div`
  pointer-events: none;
  width: 1em;
  height: 1em;
  border: 0.2em solid transparent;
  border-color: #eee;
  border-top-color: ${props => props.theme.color.primary};
  border-radius: 50%;
  animation: ${loadingspin} .5s linear infinite;
`;

export default Loading;