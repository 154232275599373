const Theme = {
  dark: {
    color: {
      bg: '#121212',
      border: '#121212',
      btnText: '#B3B3B3',
      text: '#B3B3B3',
      cardText: '#B3B3B3',
      primary: '#181818',
      navBar: '#181818',
      accent: '#F45B69',
      blend: '#59C3C3',
      dim: 'rgba(0,0,0,0.8)',
      card: '#282828',
      hoverBtnBg: 'linear-gradient(135deg, #282828 0%, #383838 100%)',
      hoverBtnTxt: '#B3B3B3',
      mockSkeletonLight: '#181818',
      mockSkeletonDark: '#121212',
      statusTxt: 'black',
      discountTxt: 'yellow',
      inputBg: '#282828',
      errorTxt: 'yellow'
    }
  },
  light: {
    color: {
      bg: '#CFD8DC',
      border: '#CFD8DC',
      btnText: 'black',
      text: 'black',
      cardText: 'black',
      primary: '#B0BEC5',
      navBar: '#B0BEC5',
      accent: '#F45B69',
      blend: '#59C3C3',
      dim: 'rgba(0,0,0,0.8)',
      card: 'white',
      hoverBtnBg: 'linear-gradient(135deg, #C5B7B0 0%, #B0C5C2 100%)',
      hoverBtnTxt: '#black',
      mockSkeletonLight: '#f0f0f0',
      mockSkeletonDark: '#d6d6d6',
      statusTxt: 'black',
      discountTxt: 'darkred',
      inputBg: 'white',
      errorTxt: 'darkred'
    }
  },
  common: {
    dim: {
      btnPadding: 10,
      fontSize: 16,
      h3Size: 17,
      h2Size: 18,
      h1Size: 19,
      smallFontSize: 14,
      navBarHeight: 60,
      padding: 10,
      phoneWidth: 750,
      minPopup: 100,
      preferPopup: 360
    },
    font: {
      default: 'AppFont'
    }
  }
};

export default Theme;