import { PersistGate } from "redux-persist/integration/react";
import usePersistStore from "./usePersistStore";
import { Provider } from 'react-redux';

const AppPersistGate = ({ children }) => {
  const { store, persistor } = usePersistStore();
  return <Provider store={store}>
    <PersistGate persistor={persistor} loading={null}>
      {children}
    </PersistGate>
  </Provider>;
};

export default AppPersistGate;