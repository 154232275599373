import { useMemo } from "react";
import useStore from "../redux/useStore";

const useNoScroll = () => {
  const store = useStore();
  const noScroll = useMemo(() => {
    return store.popupId !== null && store.popupId !== undefined;
  }, [store.popupId]);
  return noScroll;
};

export default useNoScroll;