import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import AppPersistGate from './redux/AppPersistGate';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import AppThemeProvider from './ui/AppThemeProvider';
import LoadingCenter from './ui/LoadingCenter';

// Initialize Firebase
const firebaseConfig = {
  apiKey: process.env.REACT_APP_apiKey,
  authDomain: process.env.REACT_APP_authDomain,
  projectId: process.env.REACT_APP_projectId,
  storageBucket: process.env.REACT_APP_storageBucket,
  messagingSenderId: process.env.REACT_APP_messagingSenderId,
  appId: process.env.REACT_APP_appId
};
const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);
export const fs = getFirestore(firebaseApp);
export const storage = getStorage(firebaseApp);
export const fn = getFunctions(firebaseApp);
export let analytics;
if (process.env.REACT_APP_TARGET === "client") {
  analytics = getAnalytics(firebaseApp);
}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <AppPersistGate>
        <AppThemeProvider>
          <BrowserRouter>
            <Suspense fallback={<LoadingCenter />}>
              <App />
            </Suspense>
          </BrowserRouter>
        </AppThemeProvider>
      </AppPersistGate>
    </HelmetProvider>
  </React.StrictMode>
  , document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
